import React from "react"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import _ from 'lodash'

import { Container } from "reactstrap"

import Header from "../components/header"
import Footer from "../components/footer"
import { yellow, black, gray } from "../utils/colors"

const blogContainer = css({
    margin: "0 auto",
    lineHeight: "2em",
    maxWidth: 960,
    "& a, a:hover": {
        color: black
    },
    "& a:hover": {
        backgroundColor: yellow
    },
    "& img": {
        maxWidth: "100%"
    },
    "& h1": {
        fontSize: "2rem",
        margin: "2rem 0 1rem",
        lineHeight: "1.5em"
    },
    "& h2": {
        fontSize: "1.8rem",
        fontWeight: "bold",
        margin: "4rem 0 1.5rem",
        padding: "0.5rem 0",
        borderBottom: "dotted 2px " + yellow,
    },
    "& h3": {
        fontSize: "1.3rem",
        fontWeight: "bold",
        margin: "2.5rem 0 1.2rem",
    },
    "& hr": {
        margin: "4rem 0 4rem"
    },
    "& ul": {
        margin: "24px 0",
        "& li": {
            margin: "8px 0",
            padding: "0",
            listStyle: "none",
        }
    },
    "& small": {
        fontSize: 12,
        color: gray
    },
    "& header": {
        padding: "42px 0",
        "& .date": {
            fontSize: 14,
            fontFamily: "'Halant', serif",
            margin: "0 0.5rem"
        },
        "& .category": {
            fontSize: 14,
            fontFamily: "'Halant', serif",
            margin: "0 0.5rem"
        },
        "& ul.tag": {
            fontSize: 12,
            display: "block",
            margin: "0.5rem -4px",
            "& a, a:hover": {
                backgroundColor: "rgba(0,0,0,0)"
            },
            "& li": {
                display: "inline-block",
                backgroundColor: yellow,
                margin: "0 4px",
                padding: "0 8px",
                listStyle: "none",
            }
        }
    },
    "& .article-body": {
        padding: "0 0 56px",
    }
})

export default ({ data }) => {
    const post = data.markdownRemark
    const { title, description, siteUrl, siteName } = data.site.siteMetadata
    const pageTitle = post.frontmatter.title
    const pageDescription = post.frontmatter.description
    const { published, category, tags, topImage } = post.frontmatter
    const html = post.html
    return (
        <div>
            <Helmet>
                <meta name="description" content={pageDescription + "｜" + description} />
                <title>{pageTitle + "｜" + title}</title>
                <link rel="canonical" href={siteUrl + "/contact"} />
                <meta property="og:site_name" content={siteName} />
                <meta property="og:title" content={pageTitle + "｜" + title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={siteUrl + "/contact"} />
                <meta property="og:image" content={siteUrl + topImage} />
                <meta property="og:description" content={pageDescription + "｜" + description}/>
                <meta property="fb:app_id" content="912059452500297" />
                <meta property="og:locale" content="ja_JP" />
            </Helmet>

            <Header/>
            <Container css={blogContainer}>
                <header className="text-center">
                    <img src={topImage} alt="記事イメージ"/>
                    <h1>{pageTitle}</h1>
                    <span className="date">{published}</span>
                    {/* <Link to={`/blog/categories/${category}`}><span className="category">{category}</span></Link> */}
                    <ul className="tag">
                        {
                            tags && tags.map((t, i) => (<Link key={i} to={`/blog/tags/${_.kebabCase(t)}/`}><li>{t}</li></Link>))
                        }
                    </ul>
                </header>

                <div className="article-body" dangerouslySetInnerHTML={{ __html: html }} />
            </Container>
            <Footer/>
        </div>
    )
}

export const query = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
              title,
              description,
              siteUrl
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title,
                description,
                published(formatString: "YYYY/MM/DD"),
                category,
                tags,
                topImage,
            }
        }
    }
`
